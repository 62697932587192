<template>
  <div>
    <a-tabs :active-key="activeKey" @change="changeTab">
      <a-tab-pane
        v-for="item in showHasPermissionTabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { hasPermission } from '@/utils/permission'
import { findCarrierAccountName } from '@/api/carrier_account'

export default {
  name: 'ShowCarrierAccountTab',
  data() {
    return {
      activeKey: '',
      allTabs: [
        { routeName: 'carrier_account_info', tabName: '账户信息', menu: 'carrier_accounts/show' },
        { routeName: 'carrier_account_products', tabName: '账户套餐', menu: 'carrier_products' },
        { routeName: 'carrier_sim_cards', tabName: '已划拨卡号列表', menu: 'carrier_sim_cards' },
        { routeName: 'carrier_sms_templates', tabName: '短信模版列表', menu: 'carrier_sms_templates' }
      ],
      showHasPermissionTabs: [] // 有权限的tabs
    }
  },
  computed: {
    carrierAccountId() {
      return parseInt(this.$route.params.id)
    }
  },
  watch: {
    carrierAccountId() {
      this.setBreadcrumb()
    }
  },
  created() {
    this.activeKey = this.$route.name
    this.fetchShowHasPermissionTabs()
    this.setBreadcrumb()
  },
  methods: {
    setBreadcrumb() {
      findCarrierAccountName(this.carrierAccountId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':carrier_account_name': res.data.name })
        }
      })
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchShowHasPermissionTabs() {
      let isIncludeActiveKey = false

      this.allTabs.forEach((value) => {
        if (this.isShowMenu(value.menu)) {
          this.showHasPermissionTabs.push(value)
          if (value.routeName === this.activeKey) {
            isIncludeActiveKey = true
          }
        }
      })

      // 如果有权限的目录中不包含activeKey，那么activeKey 等于目录中第一个路径名
      if (!isIncludeActiveKey) {
        this.activeKey = this.showHasPermissionTabs[0].routeName
      }
    },

    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    }
  }
}
</script>
